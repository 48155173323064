import React from "react";
import UrlExpired from "views/ExpiryLinks/Detail/components/UrlExpired";

// utils
import ExpiryLinkDetail from "views/ExpiryLinks/Detail/Detail";

// const defaultAccess = isAdmin || isSuperAdmin;

export const primaryMenuRoutes = [
  {
    access: true,
    title: "Expiry Link",
    path: ":code",
    component: () => <ExpiryLinkDetail />,
  },
  {
    access: true,
    title: "Expired",
    path: "expired",
    component: () => <UrlExpired />,
  },
];

export const secondaryMenuRoutes = [
  // {
  //   access: defaultAccess,
  //   title: "Vendors",
  //   path: enums.models["vendor-portal"].users + "/:code",
  //   component: () => (defaultAccess ? <VendorDetail /> : <HomePage />),
  // },
];
