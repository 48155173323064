import React from "react";
import comingSoon from "../../@ui/assets/backgrounds/comingSoon.png";
import { createGlobalStyle } from "styled-components";

const HomePage = () => {
  const GlobalStyle = createGlobalStyle`
   body {
    background: #FFFFFF;
  font-family: 'Advent Pro', sans-serif; 
  min-height: 100vh;
  padding-inline: 2vh;overflow:hidden;
  }
 
`;

  return (
    <div style={{ backgroundColor: "white" }}>
      <GlobalStyle />
      <img
        src={comingSoon}
        alt="Coming Soon..."
        style={{ height: "90vh", width: "80vw" }}
      />
    </div>
  );
};

export default HomePage;
