import React, { useCallback, useEffect, useState } from "react";
import { fetchValidProducts } from "../../api";
import { Grid } from "@mui/material";
import AppBarComponent, { staticViews } from "./AppBarComponent";
import { useParams } from "react-router-dom/dist/index";
import UrlExpired from "./UrlExpired";
import { useInView } from "react-intersection-observer";
import ScrollTopButton from "@ui/Actions/Scroll/ScrollTopButton";
import {
  createRecordWithHook,
  getSingleRecordWithHook,
  searchWithHook,
} from "@ui/ComponentUtils/blueprintAPIs";
import { updateRecordWithHook } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
import enums from "helpers/enums";
import Emitter from "@ui/Utils/CustomEventEmitter";
import Cart from "./Cart/Cart";
import SearchFilter, {
  getLinkItProductsMongooseCriteria,
  sessionStorageConstants,
} from "./SearchFilter";
import { MyCarousel } from "@ui/MuiComponents/index";
import miningPNG from "../assets/mining.png";
import LINKitTitle from "./LINKitTitle";
import { logout } from "@ui/Auth/api";
import ProductCard from "./ProductCard";

export const pageSize = 50;
export const handleQuantityChange = (event, setQuantity) => {
  let value = parseInt(event.target.value || 0);
  if (value > 999) {
    return Emitter.emit("alert_error", "Maximum Quantity Allowed: 999");
  }
  // else if (value < 0) {
  //   value = 0;
  // }

  if (/^\d*$/.test(value)) {
    setQuantity(value || "");
  }
};

const ECommerce = ({ user }) => {
  const [products, setProducts] = useState([]);
  const [expiredLink, setExpiredLink] = useState(false);
  const [cart, setCart] = useState([]);
  const [cartCode, setCartCode] = useState("");
  const [view, setView] = useState(staticViews.products); // "products" or "cart"
  const [selected, setSelected] = useState([]);
  const [gridView, setGridView] = useState(false);
  const [preview, setPreview] = useState([]);
  const [allowedCatalogues, setAllowedCatalogues] = useState([]);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [oneTimeCartFlag, setOneTimeCartFlag] = useState(false);

  const [paginationInfo, setPaginationInfo] = useState({
    currentPageNo: 1,
    // perPageRecords: 50,
    // totalPages: 38,
    totalRecords: "",
  });

  const [filterCriteria, setFilterCriteria] = useState({
    // styleNo: "",
    // sku: "",
    // grossWeight: { from: "", to: "" },
    // netWeight: { from: "", to: "" },
    // category: [],
    // subCategory: [],
    // collectionLine: [],
    // purity: [],
  });
  const [appliedFilterCriteria, setAppliedFilterCriteria] = useState({}); // for filter icon badge
  const [OGfilter, setOGfilter] = useState({}); // for filterCriteria in SearchFilter Component
  const [overallRemarks, setOverallRemarks] = useState("");

  const params = useParams();

  const fetchOrdersCount = async () => {
    try {
      setOrders(
        (
          await searchWithHook(enums.models.linkItOrders, {
            filterColumns: {
              orderId: 1,
              dateCreated: 1,
              "items.orderStatus": 1,
              overallRemarks: 1,
              "items.grossWeight": 1,
              "items.quantity": 1,
              "items.product.grossWeight": 1,
            },
            sort: { _id: -1 },
          })
        )?.records || []
      );
    } catch (err) {}
  };

  useEffect(() => {
    fetchOrdersCount();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (user?._id && (!oneTimeCartFlag || view === staticViews.cart)) {
      // because of adding new || condition, we lost a feature of even if cart api failed, localstates for cart would have been enough till they place order only from localStates without api.. it is still wrong and doesn't suit requirement.. just a thought..
      (async () => {
        try {
          const cartObj = await getSingleRecordWithHook(
            enums.models.linkItCarts
          );
          if (cartObj?.code) {
            setCart(cartObj.items || []);
            setCartCode(cartObj?.code);
            setOneTimeCartFlag(true);
          }
        } catch (err) {}
      })();
    }
  }, [view]); // user

  const fetchProducts = useCallback(
    async (page, pageSize, productsCriteria) => {
      const shortCode = params?.code;

      try {
        const res = await fetchValidProducts({
          page,
          pageSize,
          criteria: { productsCriteria: productsCriteria, shortCode },
        });
        const sessionDivision = window.sessionStorage.getItem("division");

        if (
          res.accessProvided?.division &&
          !(
            sessionDivision ||
            sessionDivision == "null" ||
            sessionDivision == "undefined"
          )
        ) {
          window.sessionStorage.setItem(
            "division",
            JSON.stringify(res.accessProvided.division)
          );
        }
        if (res.accessProvided?.catalogues?.length) {
          setAllowedCatalogues(res.accessProvided.catalogues);
        }

        if (res.expiredLink) {
          // setView(staticViews.cart);
          Emitter.emit("alert_error", "Contact Admin. Session Expired");
          return logout("data-engine", "/#/expired");
        }
        setExpiredLink(res.expiredLink);
        setPaginationInfo(res.paginationInfo);
        if (page == 1) {
          setProducts(res.products);
        } else {
          setProducts((prev) => [...prev, ...res.products]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  useEffect(() => {
    const sessionView = window.sessionStorage.getItem("view");

    if (
      view === staticViews.products &&
      // (sessionView && !(sessionView == "null" || sessionView == "undefined")
      //   ? sessionView == staticViews.products
      //   : true) &&
      params.code &&
      paginationInfo.totalPages !== paginationInfo.currentPageNo
    ) {
      const localSessionProductFilter = sessionStorage.getItem(
        sessionStorageConstants.productsCriteria
      );
      let productsCriteria;

      if (
        !Object.values(productsCriteria || {}).find((val) => val) &&
        localSessionProductFilter
      ) {
        productsCriteria = JSON.parse(localSessionProductFilter);

        setAppliedFilterCriteria(productsCriteria);
        setFilterCriteria(productsCriteria);
        setOGfilter(productsCriteria);

        productsCriteria = getLinkItProductsMongooseCriteria(productsCriteria);

        fetchProducts(page, pageSize, productsCriteria);
      } else {
        fetchProducts(page, pageSize);

        setAppliedFilterCriteria({});
      }
    }
  }, [params.code, page, pageSize, view]);

  const handleAddToCart = async (product, { quantity } = {}) => {
    try {
      let cartItems = [],
        updateIndex = -1;

      const updatedCart = [...cart];

      let existingProductIndex = updatedCart.findIndex(
        (item) => item.product?._id === product?._id
      );
      if (existingProductIndex === -1) {
        existingProductIndex = updatedCart.length;
      }

      updatedCart[existingProductIndex] =
        updatedCart[existingProductIndex] || {};

      if (quantity) {
        updatedCart[existingProductIndex].quantity = quantity;
      } else {
        updatedCart[existingProductIndex].quantity =
          (updatedCart[existingProductIndex].quantity || 0) + 1;
      }

      if (product.division?._id) {
        updatedCart[existingProductIndex].division = product.division;
      }

      updatedCart[existingProductIndex].product = product;
      cartItems = updatedCart;
      updateIndex = existingProductIndex;

      setCart(updatedCart);

      if (cartCode) {
        await updateRecordWithHook(enums.models.linkItCarts, {
          code: cartCode,
          [`items.${updateIndex}`]: cartItems[updateIndex],
        });
      } else {
        const res = await createRecordWithHook(enums.models.linkItCarts, {
          items: cartItems,
        });
        setCartCode(res.code);
      }

      // Emitter.emit("alert_success", "Added to Cart");
    } catch (err) {}
  };

  const handleRemoveFromCart = async (
    product,
    { quantityOnly = false } = {}
  ) => {
    try {
      let cartItems = cart
        .map((item) =>
          item.product?._id === product?._id
            ? quantityOnly && item.quantity - 1 >= 1
              ? { ...item, quantity: item.quantity - 1 }
              : null
            : item
        )
        .filter((item) => item);

      setCart(cartItems);

      await updateRecordWithHook(enums.models.linkItCarts, {
        code: cartCode,
        items: cartItems,
      });
    } catch (err) {}
  };

  const handleSelect = (product) => {
    setSelected((prevSelected) =>
      prevSelected.includes(product._id)
        ? prevSelected.filter((id) => id !== product._id)
        : [...prevSelected, product._id]
    );
  };

  const placeOrder = async () => {
    try {
      if (window.confirm("PLACE ORDER: confirm?")) {
        const res = await createRecordWithHook(enums.models.linkItOrders, {
          items: cart,
          overallRemarks: overallRemarks,
        });

        setCart([]);
        setOverallRemarks("");
        setOrders([res, ...orders]);
        Emitter.emit("alert_success", "Order Placed Successfully");
      }
    } catch (err) {
      Emitter.emit("alert_error", "Contact Admin, Invalid Products Found");
    }
  };

  if (expiredLink && view === "products") {
    return (
      <div>
        <AppBarComponent
          cart={cart}
          expiredLink={expiredLink}
          gridView={gridView}
          view={view}
          setGridView={setGridView}
          setView={setView}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          OGfilter={OGfilter}
          setFilterCriteria={setFilterCriteria}
        />

        <UrlExpired />
      </div>
    );
  }

  return (
    <div>
      <main style={{ marginTop: "-12px", paddingBottom: "60px" }}>
        <AppBarComponent
          cart={cart}
          gridView={gridView}
          view={view}
          setGridView={setGridView}
          setView={setView}
          setFilterCriteria={setFilterCriteria}
          appliedFilterCriteria={appliedFilterCriteria}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          OGfilter={OGfilter}
        />
        {/* Add bottom padding */}
        <LINKitTitle
          paginationInfo={paginationInfo}
          staticViews={staticViews}
          view={view}
          user={user}
          totalRecords={paginationInfo.totalRecords}
        />
        {view === staticViews.products ? (
          <div>
            <ProductList
              products={products}
              onAddToCart={handleAddToCart}
              cart={cart}
              onRemoveFromCart={handleRemoveFromCart}
              selected={selected}
              onSelect={handleSelect}
              gridView={gridView}
              setPreview={setPreview}
              setPage={setPage}
              // searchFIlter
              filterCriteria={filterCriteria}
              setFilterCriteria={setFilterCriteria}
              fetchProducts={fetchProducts}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              allowedCatalogues={allowedCatalogues}
              setAppliedFilterCriteria={setAppliedFilterCriteria}
              setOGfilter={setOGfilter}
            />

            {products.length ? (
              <pre
                style={{
                  position: "relative",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#333",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "20px",
                }}
              >
                {paginationInfo.totalPages !== paginationInfo.currentPageNo ? (
                  <div>
                    {
                      "mining more GOLD "

                      // // diamond?
                    }
                    <img
                      src={miningPNG}
                      style={{ height: "50px", width: "50px" }}
                    />
                  </div>
                ) : (
                  "End of Catalogue..." // onClick, whatsapp of client whatsapp number to say if need more products, contact... // not good idea because it will be seen after every filterred results... :(
                )}
              </pre>
            ) : null}
          </div>
        ) : (
          <Cart
            cart={cart}
            overallRemarks={overallRemarks}
            setOverallRemarks={setOverallRemarks}
            onRemoveFromCart={handleRemoveFromCart}
            placeOrder={placeOrder}
            onAddToCart={handleAddToCart}
            setPreview={setPreview}
            orders={orders}
            selected={selected}
            gridView={gridView}
            onSelect={handleSelect}
            setCart={setCart}
          />
        )}

        <ScrollTopButton style={{ bottom: "84px" }} />
        {preview.length ? (
          <MyCarousel
            items={preview}
            setOpenPreview={() => setPreview([])}
            openPreview={true}
            hideAction={{ download: true }}
          />
        ) : null}
      </main>
    </div>
  );
};

const ProductList = ({
  products,
  onAddToCart,
  cart,
  onRemoveFromCart,
  selected,
  onSelect,
  gridView,
  setPreview,
  setPage,

  // for SearchFilter
  filterCriteria,
  setFilterCriteria,
  fetchProducts,
  setShowFilter,
  showFilter,
  allowedCatalogues,
  setAppliedFilterCriteria,

  setOGfilter,
}) => {
  const { ref, inView } = useInView({
    threshold: 1.0,
    onChange: () => {
      if (inView && products.length) {
        setPage((prevPage) => prevPage + 1);
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        overflowY: "auto", // Allows scrolling if content exceeds view
        padding: "16px",
        // boxSizing: "border-box",
        width: "100%", // Ensure the grid spans the full width
        flexWrap: "wrap", // Ensures items wrap within the grid
      }}
    >
      <SearchFilter
        allowedCatalogues={allowedCatalogues}
        fetchProducts={fetchProducts}
        filterCriteria={filterCriteria}
        setFilterCriteria={setFilterCriteria}
        setAppliedFilterCriteria={setAppliedFilterCriteria}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        setOGfilter={setOGfilter}
      />

      {products.map((product, index) => (
        <Grid
          item
          xs={gridView ? 6 : 12} // 2 columns in grid view on mobile, 1 in list view
          sm={gridView ? 6 : 12} // Same for small screens
          md={gridView ? 3 : 6} // 4 columns in grid view on medium, 2 in list view
          lg={gridView ? 2 : 6} // 6 columns in grid view on large, 2 in list view
          key={index}
          style={{ marginBottom: "48px" }}
        >
          <ProductCard
            product={product}
            onAddToCart={onAddToCart}
            cart={cart}
            onRemoveFromCart={onRemoveFromCart}
            selected={selected}
            onSelect={onSelect}
            gridView={gridView}
            setPreview={setPreview}
          />
        </Grid>
      ))}
      <div ref={ref}></div>
    </Grid>
  );
};

export default ECommerce;
