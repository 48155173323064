import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Badge,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { ShoppingCart, ViewModule, ViewList } from "@mui/icons-material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import "./AppBarComponent.css";
import enums from "helpers/enums";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import WindPowerOutlinedIcon from "@mui/icons-material/WindPowerOutlined";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";

export const staticViews = {
  cart: "cart",
  products: "products",
  filterFields: "filter",
  logout: "logout",
};

export const countActiveFilters = (appliedFilterCriteria) => {
  let count = 0;

  if (
    appliedFilterCriteria.grossWeight?.from?.toString().trim() ||
    appliedFilterCriteria.grossWeight?.to?.toString().trim()
  )
    count++;

  if (
    appliedFilterCriteria.netWeight?.from?.toString().trim() ||
    appliedFilterCriteria.netWeight?.to?.toString().trim()
  )
    count++;

  if (appliedFilterCriteria.category?.length)
    count += appliedFilterCriteria.category.length;
  if (appliedFilterCriteria.subCategory?.length)
    count += appliedFilterCriteria.subCategory.length;
  if (appliedFilterCriteria.collectionLine?.length)
    count += appliedFilterCriteria.collectionLine.length;
  if (appliedFilterCriteria.purity?.length)
    count += appliedFilterCriteria.purity.length;

  if (appliedFilterCriteria.searchText?.toString().trim()) count++;

  return count;
};

const AppBarComponent = ({
  gridView,
  setView,
  cart,
  view,
  appliedFilterCriteria,
  showFilter,
  setShowFilter,
  OGfilter,
  setFilterCriteria,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const activeFilters = countActiveFilters(appliedFilterCriteria);
  // Restore view from sessionStorage on component mount
  useEffect(() => {
    const storedView = sessionStorage.getItem("view");
    if (storedView) {
      setView(storedView); // Restore the view from sessionStorage
    }
  }, [setView]);

  // Store the view in sessionStorage whenever it changes
  const handleViewChange = (newView) => {
    if (showFilter) {
      setShowFilter(!showFilter);
    }
    setView(newView);
    sessionStorage.setItem("view", newView); // Save the view to sessionStorage
  };

  return (
    <AppBar
      position="fixed"
      className="custom-app-bar"
      style={{
        bottom: "0",
        left: "0",
        right: "0",
        top: "auto",
        zIndex: 1300, // Ensure it's on top
      }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="icon-group">
          {/* Icons with uniform spacing */}

          {view === staticViews.cart ? null : ( // <div style={{ width: "64px" }}></div>
            <IconButtonWithLabel
              icon={
                <Badge badgeContent={activeFilters} color="secondary">
                  {showFilter ? (
                    <FilterAltOffIcon />
                  ) : (
                    <FilterAltOutlinedIcon />
                  )}
                </Badge>
              }
              // label={showFilter ? "Abort" : "Filter"}
              showLabel={!isMobile}
              onClick={() => {
                if (showFilter) {
                  setFilterCriteria(OGfilter);
                }
                setShowFilter(!showFilter);
              }}
              view={view}
              OGview={staticViews.filterFields}
            />
          )}

          <IconButtonWithLabel
            icon={gridView ? <ViewList /> : <ViewModule />}
            label={"Products"}
            showLabel={!isMobile}
            onClick={() => {
              handleViewChange(staticViews.products);
              setFilterCriteria(OGfilter);
            }}
            view={view}
            OGview={staticViews.products}
          />

          <IconButtonWithLabel
            icon={<CartIconButton cart={cart} setView={setView} />}
            label="Cart"
            showLabel={!isMobile}
            onClick={() => handleViewChange("cart")}
            OGview={staticViews.cart}
            view={view}
          />

          <IconButtonWithLabel
            icon={
              <MyHoverIcons
                muiIcon={
                  <PowerSettingsNewOutlinedIcon style={{ fontSize: "16px" }} />
                }
                muiIconHover={
                  <WindPowerOutlinedIcon style={{ fontSize: "16px" }} />
                }
              />
            }
            label={"Logout"}
            showLabel={!isMobile}
            onClick={() => {
              if (window.confirm("Confirm logout?")) {
                window.sessionStorage.clear();
                window.location.reload();
                // Emitter.emit("alert_success", "")
              }
            }}
            view={view}
            OGview={staticViews.logout}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

const CartIconButton = ({ cart, setView }) => {
  const [previousQuantity, setPreviousQuantity] = useState(0);
  const [animate, setAnimate] = useState(false);

  const totalQuantity = (cart || []).reduce((a, b) => a + b.quantity, 0);

  useEffect(() => {
    if (totalQuantity !== previousQuantity) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 500);
      setPreviousQuantity(totalQuantity);
    }
  }, [totalQuantity, previousQuantity]);

  return (
    <Badge
      badgeContent={totalQuantity}
      color="secondary"
      className={animate ? "animate" : ""}
    >
      <ShoppingCart onClick={() => setView("cart")} />
    </Badge>
  );
};

// Reusable component to handle icon + label as a button with background
const IconButtonWithLabel = ({
  icon,
  label,
  showLabel,
  onClick,
  view,
  OGview,
}) => {
  return (
    <Button
      onClick={onClick}
      style={{
        backgroundColor:
          view === OGview ? enums.colorTheme.secondary : "#d2e3fa", // Button background color
        color:
          view === OGview
            ? enums.colorTheme.secondaryLight
            : enums.colorTheme.secondary,
        padding: "8px 16px",
        display: "flex",
        alignItems: "center",
        gap: "8px", // Space between icon and text
        textTransform: "none", // Disable uppercase text
        borderRadius: "8px",
      }}
    >
      {icon}
      {showLabel && <Typography variant="caption">{label}</Typography>}
    </Button>
  );
};

export default AppBarComponent;
