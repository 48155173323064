import React, { useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Typography,
  //   IconButton,
  Grid,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  formatDateToRead,
  formatWeight,
  roundOff,
  uniqueArray,
} from "@ui/Utils/helper";
import MatTable from "@ui/components/MatTable";
import enums from "helpers/enums";

const OrdersTable = ({ orders }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return <OrdersTableMobile orders={orders} />;
  }

  // Fallback to table view for desktop
  return (
    <MatTable
      title={"# orders"}
      data={orders}
      columns={[
        { title: "Order ID", field: "orderId" },
        { title: "Items", render: (row) => row.items?.length },
        {
          title: "Weight",
          render: (row) =>
            roundOff(
              (row.items || []).reduce((a, b) => a + b.product?.grossWeight, 0),
              { weight: true }
            ),
        },
        {
          title: "Quantity",
          render: (row) =>
            (row.items || []).reduce((a, b) => a + b.quantity, 0),
        },
        { title: "Remarks", field: "overallRemarks" },
        {
          title: "Status",
          render: (row) => (
            <div>
              <div>
                {uniqueArray(
                  (row.items || []).map((item) => item.orderStatus)
                ).join(", ")}
              </div>
              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                {row.dateCreated ? formatDateToRead(row.dateCreated) : ""}
              </div>
            </div>
          ),
        },
      ]}
      options={{
        search: true,
        searchFieldStyle: {
          marginLeft: isMobile ? 0 : "10px",
          width: isMobile ? "150px" : "100%",
        },
        paging: !isMobile,
        padding: isMobile ? "dense" : "default",
      }}
    />
  );
};

export default OrdersTable;

const OrdersTableMobile = ({ orders }) => {
  //   const [expandedRows, setExpandedRows] = useState({});

  //   const handleExpandClick = (orderId) => {
  //     setExpandedRows((prev) => ({ ...prev, [orderId]: !prev[orderId] }));
  //   };

  return (
    <Grid container spacing={2}>
      {orders.length ? (
        <h4 style={{ padding: "16px", marginBottom: "-24px" }}>
          #orders = {orders.length}
        </h4>
      ) : null}

      {orders.map((order) => {
        const totalQuantity = (order.items || []).reduce(
          (a, b) => a + b.quantity,
          0
        );

        return (
          <Grid item xs={12} key={order.orderId}>
            <Card style={{ margin: "10px 0" }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#EFF6FF", // Background for title container
                    padding: "10px",
                    borderRadius: "4px",
                  }}
                >
                  {/* Order ID (60% width) */}
                  <div
                    style={{
                      width: "60%", // 60% space reserved for Order ID
                    }}
                  >
                    <div>
                      <Typography
                        variant="h6"
                        style={{
                          fontFamily: "monospace", // Monospace font
                          fontWeight: "bold",
                          color: "#075985", // Order ID font color
                          whiteSpace: "nowrap", // Prevents text wrapping for Order ID
                          overflow: "hidden",
                          textOverflow: "ellipsis", // If Order ID becomes too long, it will be truncated with '...'
                        }}
                      >
                        #{order.orderId}
                      </Typography>
                    </div>

                    <div
                      style={{
                        fontStyle: "italic",
                        fontSize: "12px",
                        color: "#6B7280",
                      }}
                    >
                      <Typography style={{ marginRight: "8px" }}>
                        {order.dateCreated
                          ? formatDateToRead(order.dateCreated)
                          : ""}
                      </Typography>
                    </div>
                  </div>

                  {/* Date Created and Arrow (40% width) */}
                  <div
                    style={{
                      color: enums.colorTheme.primary,
                      width: "40%", // Remaining space for Date and Arrow
                      //   color: "black",
                      fontWeight: "bold",
                      padding: "10px",
                      fontStyle: "italic",
                      textAlign: "center",
                    }}
                  >
                    <Typography>
                      {uniqueArray(
                        (order.items || []).map((item) => item.orderStatus)
                      ).join(", ")}
                    </Typography>
                  </div>

                  {/* <IconButton onClick={() => handleExpandClick(order.orderId)}>
                      {expandedRows[order.orderId] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton> */}
                </div>

                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#374151",
                    //   fontFamily: "monospace", // Monospace font
                  }}
                >
                  <Typography>
                    <b style={{ color: "grey" }}>Weight:</b>{" "}
                    {formatWeight(
                      (order.items || []).reduce(
                        (a, b) => a + b.product?.grossWeight * b.quantity,
                        0
                      ),
                      { weight: true }
                    )}
                  </Typography>

                  <Typography
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <b style={{ color: "grey" }}>Quantity:&nbsp;</b>
                    {totalQuantity}
                  </Typography>
                </div>

                <div>
                  {order.overallRemarks ? (
                    <Typography style={{ color: "#374151" }}>
                      <pre>
                        <b style={{ color: "grey" }}>Remarks:</b>
                      </pre>
                      {order.overallRemarks}
                    </Typography>
                  ) : null}
                </div>

                {/* {expandedRows[order.orderId] && (
                  
                )} */}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
