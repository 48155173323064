import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "@ui/components/AuthProvider";
import SnackbarComponent from "@ui/components/SnackbarComponent";
import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import OTP from "./@ui/Auth/OTP";
import ResetPassword from "./@ui/Auth/ResetPassword";
import SendOTP from "./@ui/Auth/SendOTP";
import HomePage from "./pages/HomePage/HomePage";

import Loader from "./components/Loader";
import { primaryMenuRoutes, secondaryMenuRoutes } from "routes";
import typographyTheme from "./@ui/assets/theme/typographyTheme";
import InternetConnectionChecker from "@ui/Actions/InternetConnection/InternetConnectionChecker";

const theme = createTheme({
  typography: {
    typographyTheme,
  },
  direction: "ltr",
});

const GlobalStyle = createGlobalStyle`
   body {
    
  font-family: 'Roboto', sans-serif; 
  min-height: 100vh;
  overflow:hidden;
  }
 
`;

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <SnackbarComponent />
          <GlobalStyle />
          <Loader />

          <BrowserRouter>
            {/* <DivisionControl userInfo={userInfo} /> */}
            <InternetConnectionChecker />
            <Routes>
              <Route path="*" element={<HomePage />} />
              <Route path="*/:home" element={<HomePage />} />
              <Route path="otp" element={<OTP />} exact />
              <Route path="sendOtp" element={<SendOTP />} exact />
              <Route path="resetPassword" element={<ResetPassword />} exact />

              {[...primaryMenuRoutes, ...secondaryMenuRoutes].map(
                (route, i) => {
                  return (
                    <Route
                      key={i}
                      path={route.path}
                      element={route.component()}
                      exact
                    />
                  );
                }
              )}
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
