import React from "react";
import { Typography, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
});

function LINKitTitle({
  // paginationInfo,
  view,
  staticViews,
  user,
  totalRecords,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Box textAlign="center" sx={{ userSelect: "none", mt: 4 }}>
        {view === staticViews.products && (
          <>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "#5e5e5e",
                letterSpacing: "0.5px",
                fontSize: "2rem",
              }}
            >
              LINK it
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                fontStyle: "italic",
                color: "#777",
                fontSize: "1rem",
              }}
            >
              {user.client?.name} <br />
              <b
              // style={{ paddingTop: "-24px" }}
              >
                {totalRecords === 0
                  ? "No Product Found"
                  : totalRecords
                  ? totalRecords
                  : ""}{" "}
                {!totalRecords ? "" : totalRecords > 1 ? "Products" : "Product"}
              </b>
            </Typography>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default LINKitTitle;

//
