import React, { useEffect } from "react";
import { Button, Typography, Grid, TextField } from "@mui/material";
// import { useMediaQuery } from "@mui/material";
// import { formatDateToRead, uniqueArray } from "@ui/Utils/helper";
import { formatIndianNumber, roundOff } from "@ui/Utils/helper";
// import MatTable from "@ui/components/MatTable";
import ProductCard from "../ProductCard";
import OrdersTable from "./OrdersTable";
import CartHeader from "./CartHeader";
// import { Badge, IconButton } from "@mui/material";
// import { Tooltip } from "@mui/material";
// import ClearIcon from "@mui/icons-material/Clear";

const Cart = ({
  cart,
  onRemoveFromCart,
  placeOrder,
  onAddToCart,
  setPreview,
  orders,
  selected,
  gridView,
  onSelect,
  setCart,
  overallRemarks,
  setOverallRemarks,
}) => {
  // const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  /*
  const downloadPdf = async (order) => {
    try {
      const filteredItems = order.items;

      let totalGrossWeight = 0,
        totalNetWeight = 0,
        totalQuantity = 0;

      const pdfProducts = filteredItems.map((item) => {
        item = item || {};
        item.product = item.product || {};

        totalGrossWeight += Number(item.product?.grossWeight) || 0;
        totalNetWeight += Number(item.product?.netWeight) || 0;
        totalQuantity += Number(item.quantity) || 0;

        return {
          styleNo: item.product?.styleNo,
          grossWeight: (Number(item.product?.grossWeight) || 0).toFixed(3),
          netWeight: (Number(item.product?.netWeight) || 0).toFixed(3),
          purity: item.product?.purity?.value
            ? item.product?.purity?.value + " KT"
            : "",
          color: item.product?.color,

          adminRemarks: item.product?.remarks,
          dueDate: item?.dueDate ? formatDateToRead(item?.dueDate) : "",

          image: getProductImageUri(item.product || {}),
          quantity: item?.quantity,

          motiWeight: item?.product?.motiWeight,
          kundanWeight: item?.product?.kundunWeight,
        };
      });

      const orderDate = order.dateCreated
        ? formatDateToRead(order.dateCreated)
        : "";

      const res = await getQuotationPdfReport({
        data: {
          vendorCode: "",
          remarks: order.overallRemarks,
          orderId: order.orderId,
          orderDate: orderDate,
          products: pdfProducts,

          totalGrossWeight: totalGrossWeight.toFixed(3),
          totalNetWeight: totalNetWeight.toFixed(3),
          totalQuantity: totalQuantity,
          customerName:
            order.createdBy?.firstName + " " + order.createdBy?.lastName,
        },
        pdfTitle: "LINK it Order",
        template: "Template-3",
      });

      generatePDF(res, order.orderId);
    } catch (err) {
      console.log(err);
    }
  }; */

  return (
    <div
      className="cart"
      style={{
        marginTop: "-16px",
      }}
    >
      <CartHeader
        cart={cart}
        overallRemarks={overallRemarks}
        setOverallRemarks={setOverallRemarks}
      />

      <Button
        onClick={placeOrder}
        variant="contained"
        disabled={!cart.length}
        color="primary"
        className="place-order-button"
        onMouseEnter={(event) => {
          if (!event.target.disabled) {
            event.target.style.backgroundColor = "#075985"; // Swap background color on hover
            event.target.style.color = "#EFF6FF";
          }
        }}
        onMouseLeave={(event) => {
          if (!event.target.disabled) {
            event.target.style.backgroundColor = "#EFF6FF"; // Original background color
            event.target.style.color = "#075985";
          }
        }}
        style={{
          backgroundColor: !cart.length ? "#e0e0e0" : "#EFF6FF", // Gray background when disabled
          color: !cart.length ? "#9e9e9e" : "#075985", // Lighter text color when disabled
          cursor: !cart.length ? "not-allowed" : "pointer", // Change cursor when disabled
        }}
      >
        Place Order
      </Button>

      <Grid container spacing={2} style={{ marginTop: "24px" }}>
        {cart.map((item) => (
          <Grid
            item
            xs={gridView ? 6 : 12} // 2 columns in grid view on mobile, 1 in list view
            sm={gridView ? 6 : 12} // Same for small screens
            md={gridView ? 3 : 6} // 4 columns in grid view on medium, 2 in list view
            lg={gridView ? 2 : 6} // 6 columns in grid view on large, 2 in list view
            key={item.product?._id}
            style={{ marginBottom: "24px" }}
          >
            <ProductCard
              product={item.product || {}}
              onAddToCart={onAddToCart}
              cart={cart}
              onRemoveFromCart={onRemoveFromCart}
              selected={selected}
              onSelect={onSelect}
              gridView={gridView}
              setPreview={setPreview}
              showRemove={"true"}
            />
          </Grid>
        ))}
      </Grid>

      <div style={{ marginTop: "48px" }}>
        <div style={{ borderTop: "2px solid #ddd" }}>
          <OrdersTable orders={orders} />
        </div>
      </div>

      <style jsx>{`
        .cart {
          display: flex;
          flex-direction: column;
          padding: 16px;
        }

        .cart-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
        }

        .cart-title {
          font-weight: 600;
          color: #5e5e5e;
          letter-spacing: 0.5px;
          font-size: 2rem;
        }

        .total-stats {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          margin-bottom: 16px;
        }

        .stat {
          flex: 1 1 20%;
          margin: 0;
        }

        .place-order-button {
          padding: 8px 16px;
          width: 100%;
          text-transform: none;
          font-size: 1rem;
          font-weight: bold;
          margin-top: 16px;
        }

        @media (max-width: 600px) {
          .cart-header {
            flex-direction: column;
            align-items: flex-start;
          }

          .total-stats {
            flex-direction: column;
          }
        }
      `}</style>
    </div>
  );
};

export default Cart;
