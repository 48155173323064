import React from "react";
import "./UrlExpired.css";
import WorldClock from "./WorldClock";

const UrlExpired = () => {
  return (
    <div className="url-expired-container">
      <WorldClock />
      <div
        className="url-expired-message fade-in"
        style={{
          cursor: "wait",
          transition: "transform 0.3s",
          userSelect: "none",
        }}
      >
        ~ URL ~ EXPIRED
      </div>
    </div>
  );
};

export default UrlExpired;
