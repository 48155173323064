import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

const LoaderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${(props) => (props.loading ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(187, 187, 187, 0.6);
  z-index: 1000;
`;

const Loader = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleProgressBar = (value) => {
      setLoading((prev) => (value ? prev + 1 : prev - 1));
    };

    CustomEventEmitter.on("progressBar", handleProgressBar);

    return () => {
      CustomEventEmitter.off("progressBar", handleProgressBar);
    };
  }, []);

  return (
    <LoaderContainer loading={loading}>
      <CircularProgress color="secondary" />
    </LoaderContainer>
  );
};

export default Loader;
