import React, { useEffect, useState } from "react";
import LoginWithOTP, { sessionKeys } from "./components/LoginWithOTP";
import ECommerce from "./components/ECommerce";
import Emitter from "@ui/Utils/CustomEventEmitter";
import "./detail.css";

const ExpiryLinkDetail = () => {
  const sessionUser = sessionStorage.getItem(sessionKeys.userDetails);
  const [user, setUser] = useState(
    sessionUser && sessionUser !== "undefined" ? JSON.parse(sessionUser) : {}
  );
  // const [filterCriteria, setFilterCriteria] = useState({});

  useEffect(() => {
    const sessionUser = sessionStorage.getItem(sessionKeys.userDetails);

    if (sessionUser && sessionUser !== "undefined") {
      setUser(JSON.parse(sessionUser));
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        e.key === "PrintScreen" ||
        (e.ctrlKey && e.key === "PrintScreen") ||
        (e.metaKey && e.shiftKey && (e.key === "3" || e.key === "4"))
      ) {
        e.preventDefault();
        Emitter.emit("alert_error", "Screenshots are disabled");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (user?._id) {
    return <ECommerce user={user} />;
  }

  return <LoginWithOTP user={user} setUser={setUser} />;
};

export default ExpiryLinkDetail;
