import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  Box,
  IconButton,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import { Add, Remove, Check as CheckIcon } from "@mui/icons-material";
import {
  formatIndianNumber,
  formatWeight,
  imageExpired,
  oopsNotFound,
  roundOff,
} from "@ui/Utils/helper";
import ProductDetailPopup, {
  FullscreenPreview,
} from "@ui/MuiComponents/Carousel/ProductDetailPopup";
import { handleQuantityChange } from "./ECommerce";
import Emitter from "@ui/Utils/CustomEventEmitter";
import LazyLoad from "@ui/Actions/LazyLoad/index";

const ProductCard = ({
  product,
  onAddToCart,
  cart,
  onRemoveFromCart,
  showRemove,
}) => {
  const cartItem = cart.find((item) => item.product?._id === product?._id);
  const [quantity, setQuantity] = useState(cartItem ? cartItem.quantity : 0);
  const [imageError, setImageError] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [openImageOnly, setOpenImageOnly] = useState(false);

  useEffect(() => {
    // Reset imageError to false when product.defaultImage.signedUrl changes
    if (product?.defaultImage?.signedUrl) {
      setImageError(false);
    }
  }, [product?.defaultImage]);

  const handleImageError = () => {
    setImageError(true);
  };

  const handleAddToCart = () => {
    if (quantity > 999) {
      return Emitter.emit("alert_error", "Maximum Quantity Allowed: 999");
    }
    if (quantity > 0) {
      onAddToCart(product, { quantity });
      if (quantity <= 5) {
        setShowInput(false);
      }
    } else {
      onRemoveFromCart(product);
    }
    setShowInput(false);
  };

  const handleRemoveFromCart = () => {
    if (quantity > 0) {
      if (quantity > 6) {
        setShowInput(true);
      } else {
        setQuantity(quantity - 1);
        onRemoveFromCart(product, { quantityOnly: true });
      }
    }
  };

  const handleAddButtonClick = () => {
    if (Number(quantity) < 5) {
      setQuantity((quantity || 0) + 1);
      onAddToCart(product);
    } else {
      setShowInput(true);
    }
  };

  return (
    <Card
      style={{
        marginLeft: showRemove ? "0px" : "10px",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        padding: "16px",
        // borderRadius: "20px",
        // border: "2px solid #EFF6FF",
        borderRadius: "20px",
        border: "1px solid #0EA5E980",
        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "90%",
        height: "100%",
        // boxSizing: "border-box",
        overflow: "hidden",
        userSelect: "none",
      }}
    >
      {openDetailDialog ? (
        <ProductDetailPopup
          open={openDetailDialog}
          onClose={() => setOpenDetailDialog(false)}
          product={product}
        />
      ) : null}

      {openImageOnly ? (
        <FullscreenPreview
          open={openImageOnly}
          onClose={() => setOpenImageOnly(false)}
          imageSrc={product?.defaultImage?.signedUrl || oopsNotFound}
        />
      ) : null}

      {/* Left Side: Product Image */}

      <Box
        sx={{
          width: { xs: "100%", sm: "40%" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "20px",
          overflow: "hidden",
        }}
        onClick={() => setOpenImageOnly(true)}
      >
        <LazyLoad>
          <CardMedia
            component="img"
            // onClick={(event) => {
            //   // event.target.style.border = `2px dotted ${enums.colorTheme.secondary}`; // Set full border property
            //   // event.target.style.borderRadius = "20px";
            //   // event.target.style.margin = "4px";
            // }}
            style={{
              objectFit: "contain",
              // padding: "2px",

              // margin: "10px",
              // marginLeft: "-1px",
              maxHeight: "300px",
              display: "block",
              transition: "border 2s ease",
            }}
            image={
              imageError
                ? imageExpired
                : product?.defaultImage?.signedUrl || oopsNotFound
            }
            onError={handleImageError}
            alt={product?.styleNo}
            onContextMenu={(e) => e.preventDefault()} // Prevent right-click download option
          />
        </LazyLoad>
      </Box>

      {/* Right Side: Product Details */}
      <Box
        sx={{
          width: { xs: "100%", sm: "60%" },
          paddingLeft: { xs: "16px", sm: "16px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mt: { xs: "16px", sm: 0 },
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            fontSize: "1.2rem",
            // backgroundColor: "#EFF6FF",
            color: "#075985",
          }}
          onClick={() => setOpenDetailDialog(true)}
        >
          {product?.styleNo}
        </Typography>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "8px",
            mt: 1,
          }}
          onClick={() => setOpenDetailDialog(true)}
        >
          {/* Fields such as Color, Purity, Size */}
          <Typography variant="body2" color="textSecondary">
            Color
          </Typography>
          <Typography variant="body2">{product?.color}</Typography>

          <Typography variant="body2" color="textSecondary">
            Purity
          </Typography>
          <Typography variant="body2">{product?.purity?.value} KT</Typography>

          <Typography variant="body2" color="textSecondary">
            Size
          </Typography>
          <Typography variant="body2">{product?.size}</Typography>

          {/* Net Weight */}
          <Typography variant="body2" color="textSecondary">
            Net WT
          </Typography>
          <Typography variant="body2">
            {formatWeight(product?.netWeight)}
          </Typography>

          {/* Gross Weight */}
          <Typography variant="body2" color="textSecondary">
            Gross WT
          </Typography>
          <Typography variant="body2">
            {formatWeight(product?.grossWeight)}
          </Typography>
          {product?.totalDiamondWeight ? (
            <>
              <Typography variant="body2" color="textSecondary">
                Diamond WT
              </Typography>
              <Typography variant="body2">
                {formatWeight(product?.totalDiamondWeight, {
                  inputInCts: true,
                })}
              </Typography>
            </>
          ) : null}
        </Box>

        {product?.jewelPrice ? (
          <Typography
            variant="h6"
            style={{ color: "green", fontWeight: "bold", mt: 2 }}
          >
            ₹ {formatIndianNumber(roundOff(product?.jewelPrice))}
          </Typography>
        ) : null}

        {/* Quantity Control and Remarks */}

        <Box sx={{ mt: 2 }}>
          {quantity > 0 && !showInput ? (
            <Box
              className="quantity-control"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "auto",
              }}
            >
              <IconButton
                onClick={handleRemoveFromCart}
                onMouseEnter={(event) => {
                  event.currentTarget.style.backgroundColor = "#075985"; // Hover background color
                  event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                }}
                onMouseLeave={(event) => {
                  event.currentTarget.style.backgroundColor = "#EFF6FF"; // Original background color
                  event.currentTarget.style.color = "#075985"; // Original text color
                }}
                onMouseDown={(event) => {
                  event.currentTarget.style.backgroundColor = "#034B72"; // Active background color when clicked
                  event.currentTarget.style.color = "#075985"; // Original text color
                }}
                onMouseUp={(event) => {
                  event.currentTarget.style.backgroundColor = "#075985"; // Back to hover color after click
                  event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                }}
                style={{
                  backgroundColor: "#EFF6FF", // Initial background color
                  color: "#075985", // Initial text color
                  borderRadius: "50%",
                  userSelect: "none", // Prevent text selection
                  transition: "background-color 0.3s, color 0.3s", // Smooth transitions
                }}
              >
                <Remove />
              </IconButton>

              <span>{quantity}</span>
              <IconButton
                onClick={handleAddButtonClick}
                onMouseEnter={(event) => {
                  event.currentTarget.style.backgroundColor = "#075985"; // Hover background color
                  event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                }}
                onMouseLeave={(event) => {
                  event.currentTarget.style.backgroundColor = "#EFF6FF"; // Original background color
                  event.currentTarget.style.color = "#075985"; // Original text color
                }}
                onMouseDown={(event) => {
                  event.currentTarget.style.backgroundColor = "#034B72"; // Active background color when clicked
                  event.currentTarget.style.color = "#075985"; // Original text color
                }}
                onMouseUp={(event) => {
                  event.currentTarget.style.backgroundColor = "#075985"; // Back to hover color after click
                  event.currentTarget.style.color = "#EFF6FF"; // Hover text color
                }}
                style={{
                  backgroundColor: "#EFF6FF", // Initial background color
                  color: "#075985", // Initial text color
                  borderRadius: "50%",
                  userSelect: "none", // Prevent text selection
                  transition: "background-color 0.3s, color 0.3s", // Smooth transitions
                }}
              >
                <Add />
              </IconButton>
            </Box>
          ) : (
            <Box
              className="quantity-control"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "auto",
              }}
            >
              {showInput ? (
                <TextField
                  value={quantity || ""}
                  onChange={(e) => handleQuantityChange(e, setQuantity)}
                  onKeyDown={(e) => e.key === "Enter" && handleAddToCart()}
                  type="number"
                  autoComplete="tel"
                  variant="outlined"
                  autoFocus={true}
                  style={{
                    width: "100%",
                    // maxHeight: "20px",
                  }}
                  InputProps={{
                    style: {
                      display: "flex",
                      padding: "8px",
                    },
                    inputProps: {
                      style: {
                        fontSize: "12px",
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleAddToCart}
                          onMouseEnter={(event) => {
                            event.target.style.backgroundColor = "#075985"; // Swap background color on hover
                            event.target.style.color = "#EFF6FF";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.backgroundColor = "#EFF6FF"; // Original background color
                            event.target.style.color = "#075985";
                          }}
                          style={{
                            // marginTop: "-12px",
                            backgroundColor: "#EFF6FF",
                            color: "#075985",
                            borderRadius: "50%",
                          }}
                        >
                          <CheckIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <Button
                  // variant="contained"
                  // className="add-to-cart-button"
                  onClick={handleAddButtonClick}
                  style={{
                    padding: "8px 16px",
                    marginTop: "auto",
                    width: "100%",
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    background: "#EFF6FF",
                    color: "#075985",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.backgroundColor = "#075985"; // Swap background color on hover
                    event.target.style.color = "#EFF6FF";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.backgroundColor = "#EFF6FF"; // Original background color
                    event.target.style.color = "#075985";
                  }}
                >
                  Add to Cart
                </Button>
              )}
            </Box>
          )}

          <center>
            {showRemove && !showInput ? (
              <Button
                onClick={() => onRemoveFromCart(product)}
                variant="contained"
                // color="primary"
                onMouseEnter={(event) => {
                  event.target.style.backgroundColor = "#075985"; // Swap background color on hover
                  event.target.style.color = "#EFF6FF";
                }}
                onMouseLeave={(event) => {
                  event.target.style.backgroundColor = "#EFF6FF"; // Original background color
                  event.target.style.color = "#075985";
                }}
                style={{
                  background: "#EFF6FF",
                  color: "#075985",

                  textAlign: "center",
                  marginTop: "12px",
                  textTransform: "none",
                  letterSpacing: "0.05rem",
                  minWidth: "100px",
                }}
                // onMouseEnter={(e) => (e.target.style.backgroundColor = "blue")}
                // onMouseLeave={(e) => (e.target.style.backgroundColor = "purple")}
              >
                {`remove`}
              </Button>
            ) : null}
          </center>
          {/* Remarks Field */}
          {/* <TextField
            label="Add remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            variant="outlined"
            fullWidth
            size="small"
            sx={{ mt: 2 }}
          /> */}
        </Box>
      </Box>
    </Card>
  );
};

export default ProductCard;
