import React, { useState, useEffect } from "react";
import "./WorldClock.css";

const WorldClock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();

  // Calculate degrees for each hand
  const secondDegrees = (seconds / 60) * 360;
  const minuteDegrees = ((minutes + seconds / 60) / 60) * 360;
  const hourDegrees = ((hours + minutes / 60) / 12) * 360;

  return (
    <div className="world-clock-container pulse">
      <div className="clock-face">
        <div className="world-map" />
        <div className="clock-center" />
        <div
          className="second-hand"
          style={{ transform: `rotate(${secondDegrees}deg)` }}
        />
        <div
          className="minute-hand"
          style={{ transform: `rotate(${minuteDegrees}deg)` }}
        />
        <div
          className="hour-hand"
          style={{ transform: `rotate(${hourDegrees}deg)` }}
        />
      </div>
    </div>
  );
};

export default WorldClock;
