import React from "react";
import { Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import { formatIndianNumber, roundOff } from "@ui/Utils/helper";
import { TextField } from "../../../../../../node_modules/@material-ui/core/index";

const CartHeader = ({ cart, overallRemarks, setOverallRemarks }) => {
  // Calculate total statistics
  const totalQuantity = cart.reduce((total, item) => total + item.quantity, 0);
  const totalGrossWeight = cart.reduce(
    (total, item) => total + item.product?.grossWeight * item.quantity,
    0
  );
  const totalNetWeight = cart.reduce(
    (total, item) => total + item.product?.netWeight * item.quantity,
    0
  );
  const totalJewelPrice = cart.reduce(
    (total, item) => total + (item.product?.jewelPrice || 0) * item.quantity,
    0
  );

  // Theme and breakpoints for responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {/* Cart Title */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //   padding: "16px",
          //   borderBottom: "1px solid #ddd",
          //   backgroundColor: "#f8f8f8", // Light background for premium look
        }}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          gutterBottom
          style={{
            fontWeight: "bold",
            color: "#B8860B", // Gold color for title
          }}
        >
          Cart
        </Typography>
      </div>

      {/* Cart Stats */}
      <Grid
        container
        spacing={2}
        style={{
          padding: "16px",
          backgroundColor: "#fff", // White background for the stats section
          borderRadius: "8px",
          marginTop: "10px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for a premium feel
        }}
      >
        {/* Total Quantity */}
        <Grid item xs={12} sm={3}>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            style={{ color: "#555" }}
          >
            Quantity:
          </Typography>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            style={{ fontWeight: "bold", color: "#333" }}
          >
            {totalQuantity}
          </Typography>
        </Grid>

        {/* Total Net Weight */}
        <Grid item xs={12} sm={3}>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            style={{ color: "#555" }}
          >
            Net Weight:
          </Typography>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            style={{ fontWeight: "bold", color: "#333" }}
          >
            {roundOff(totalNetWeight, { weight: true })} g
          </Typography>
        </Grid>

        {/* Total Gross Weight */}
        <Grid item xs={12} sm={3}>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            style={{ color: "#555" }}
          >
            Gross Weight:
          </Typography>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            style={{ fontWeight: "bold", color: "#333" }}
          >
            {roundOff(totalGrossWeight, { weight: true })} g
          </Typography>
        </Grid>

        {/* Total Jewel Price */}
        <Grid item xs={12} sm={3}>
          {totalJewelPrice > 0 && (
            <>
              <Typography
                variant={isMobile ? "body1" : "h6"}
                style={{ color: "#555" }}
              >
                Total Price:
              </Typography>
              <Typography
                variant={isMobile ? "h6" : "h5"}
                style={{ fontWeight: "bold", color: "#B8860B" }} // Gold color for price
              >
                ₹ {formatIndianNumber(roundOff(totalJewelPrice))}
              </Typography>
            </>
          )}
        </Grid>

        <div style={{ width: "100%", padding: "8px", marginTop: "12px" }}>
          <TextField
            value={overallRemarks || ""}
            onChange={(e) => setOverallRemarks(e.target.value)}
            label="Overall Remarks"
            multiline
            rows={3}
            InputProps={{
              style: {
                padding: "8px",
              },
            }}
            variant="outlined"
            style={{
              width: "100%",
              // maxHeight: "20px",
            }}
          />
        </div>
      </Grid>
    </div>
  );
};

export default CartHeader;
